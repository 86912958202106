import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Link, Paragraph, Rule, Spacer } from '@atoms/index'
import { ResponsivePXValue, theme } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import {
  CategorisedUserPreferenceFragment, useUserDetailsQuery, UserPreferenceOrDefaultFragment, useCategorisedUserPreferencesLazyQuery,
  useAddUpdateCategorisedUserPreferenceMutation, useUpdateUserPreferenceByCategoryMutation, useGetAppQuery, useCategorisedUserPreferencesQuery, useCategorisedUserPreferencesHashedIdQuery, useCategorisedUserPreferencesHashedIdLazyQuery, useUpdateUserPreferenceByCategoryHashedIdMutation, useAddUpdateCategorisedUserPreferenceHashedIdMutation,
} from '@hooks/api'
import { Utilities } from '@lib/Utilities'
import { TableCell, TableRow, ContentCard, ContentCardHeaderEnum, SectionLoading, CheckBox, FieldData, Form, useForm } from '@molecules/index'
import { DeviceTypeEnum, UserStatusEnum } from '@uctypes/api/globalTypes'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'
import update from 'react-addons-update'

const Container = styled.div`

  display: flex;
  flex-direction: column;
  background-color: ${(props): string => props.theme.colors.whites.desertStorm};
  
`

const MobileContainer = styled.div`
  display: inline-block;
  ${(props): string => props.theme.desktop} {
    display: none;
  }
`

const DesktopContainer = styled.div`
  display: none;
  width: 100%;
  ${(props): string => props.theme.desktop} {
    display: inline-block;
  }
`

const CardContainer = styled.div`

  display: flex;
  flex-direction: column;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  
`

const CardSeperator = styled.div`
  width: 100%;
  ${ResponsivePXValue('height', { mobile: '24px', tablet: '28px', desktop: '32px' })}
`

const ButtonContainer = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  ${ResponsivePXValue('width', '100%')}

`

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .check-input {
    margin: 0;
    flex-grow: initial;
    div {
      font-family: gordita;
      font-weight: 400;
      ${ResponsivePXValue('font-size', { mobile: '8px', desktop: '10px' })}
      ${ResponsivePXValue('line-height', '16px')}
    }
  }
`

interface UserPreferencesState {
  loading: boolean
  userPreferences: CategorisedUserPreferenceFragment[]
  hashedUserId: string
}

const DEFAULT_STATE: UserPreferencesState = {
  loading: true,
  userPreferences: [],
  hashedUserId: '',
}

const isTrue = (value: string): boolean => {
  return value.toLowerCase() === 'true'
}

const getCategoryButtonText = (category: CategorisedUserPreferenceFragment): string => {
  const allTrue = category.preferences.every((item) => isTrue(item.userValue))
  return allTrue ? 'UNSUBSCRIBE ALL' : 'SUBSCRIBE ALL'
}

const getCategoryButtonValue = (category: CategorisedUserPreferenceFragment): boolean => {
  const allTrue = category.preferences.every((item) => isTrue(item.userValue))
  return allTrue
}

const seo = {
  name: 'User Dashboard',
  title: 'User Dashboard',
  meta: [{
    name: 'robots',
    content: 'noindex,nofollow',
  }],
}

export function EmailUserPreferencesList(): JSX.Element {

  const config = useConfig()
  const currentUrl = config.isBrowser() && window.location.href

  const searchParams = new URLSearchParams(new URL(currentUrl).search)
  const hid = searchParams.get('hid')


  const [getUserPreferencesHashedId, { data: preferencesData, loading: preferencesLoading }] = useCategorisedUserPreferencesHashedIdLazyQuery()

  const { data: userData, loading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })

  // if userData redirect to the preference page in account else this
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const [state, setState] = useState<UserPreferencesState>({ ...DEFAULT_STATE })
  const [updateCategoryHashedId] = useUpdateUserPreferenceByCategoryHashedIdMutation()
  const [addUpdatePreferenceHashedId] = useAddUpdateCategorisedUserPreferenceHashedIdMutation()
  const navigate = useNavigate()
  const { addToast } = useToasts()
  const [form] = useForm()

 
  const _handleTogglePreference = async (preference: UserPreferenceOrDefaultFragment): Promise<void> => {
    setState((prevState) => ({ ...prevState, loading: true }))

    const preferenceValue = Utilities.parseBoolean(preference.userValue)
    try {
      await addUpdatePreferenceHashedId({
        variables: {
          input: {
            userValue: preferenceValue + '',
            user: state.hashedUserId,
            preference: preference.id,
          },
        },
      })
      const messagePart1 = preferenceValue ? 'Added' : 'Removed'
      const messagePart2 = preferenceValue ? 'to' : 'from'
      addToast(`${messagePart1} ${preference.name} ${messagePart2} your email preferences list`, {
        appearance: preferenceValue ? 'success' : 'warning',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setState((prevState) => ({ ...prevState, loading: false }))
  }

  const _handleToggleCategory = async (category: CategorisedUserPreferenceFragment): Promise<void> => {
    setState((prevState) => ({ ...prevState, loading: true }))

    const categoryValue = !getCategoryButtonValue(category)

    try {
      await updateCategoryHashedId({
        variables: {
          input: {
            preferenceCategoryId: category.id,
            user: state.hashedUserId,
            userValue: categoryValue + '',
          },
        },
      })
      const messagePart1 = categoryValue ? 'Added' : 'Removed'
      const messagePart2 = categoryValue ? 'to' : 'from'
      addToast(`${messagePart1} all ${category.name} ${messagePart2} your email preferences list`, {
        appearance: categoryValue ? 'success' : 'warning',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setState((prevState) => ({ ...prevState, loading: false }))
  }

  const _handleChange = (changedFields: FieldData[]) => {

    changedFields.forEach((field) => {

      const fieldName = field.name[0] as string[]

      if (fieldName.includes('|')) {

        const isSelected = field.value.length > 0

        const [categoryId, preferenceId] = field.name[0].split('|')

        const preference = state.userPreferences.find((category) => category.id === categoryId)?.preferences?.find((preference) => preference.id === preferenceId)

        const updatedPreference = {
          ...preference,
          userValue: isSelected + '',
        }

        _handleTogglePreference(updatedPreference)
      }

    })
  }

  useEffect(() => {
    if (hid.length){
      getUserPreferencesHashedId({ variables: { id: hid } })
    }
  }, [hid])
  

  useEffect(() => {
    if (preferencesData?.categorisedUserPreferencesHashedId) {
      const category = preferencesData.categorisedUserPreferencesHashedId.list[0]
      const preferences = category.preferences

      preferences.forEach((preference) => {
        if (Utilities.parseBoolean(preference.userValue)) {
          form.setFieldsValue({ [`${category.id}|${preference.id}`]: [Utilities.parseBoolean(preference.userValue)] })
        }
      })

      // setState((prevState) => ({ ...prevState, userPreferences: preferencesData.categorisedUserPreferences.list as CategorisedUserPreferenceFragment[] }))


      setState((prevState) => update(prevState, {
        userPreferences: { $set: preferencesData.categorisedUserPreferencesHashedId.list as CategorisedUserPreferenceFragment[] },
        hashedUserId: { $set: hid}
      }))
      
    }
  }, [preferencesData])

  let category: CategorisedUserPreferenceFragment
  let preference: UserPreferenceOrDefaultFragment

  return (
    <>
      <SearchEngineOptimization seo={seo} />
      <Choose>
        <When condition={!!state.userPreferences}>
          <Container>
            <Choose>
              <When condition={state.userPreferences.length > 0} >
                <Form form={form} onFieldsChange={_handleChange}>
                  <For each='category' of={state.userPreferences}>
                    <Choose>
                      <When condition={isMobile} >
                        <MobileContainer key={category.id}>
                          <ContentCard title={category.name} headerType={ContentCardHeaderEnum.TITLE_ONLY}>
                            <CardContainer>
                              <For each='preference' of={category.preferences}>
                                <TableRow>
                                  <TableCell padding='24px' justify='center' align='center' direction='column'>
                                    <CheckboxContainer>
                                      <CheckBox
                                        name={`${category.id}|${preference.id}`}
                                        showLabel={false}
                                        showOptional={false}
                                        options={[{ title: '', value: Utilities.parseBoolean(preference.userValue) }]}
                                        className='check-input' />
                                    </CheckboxContainer>
                                  </TableCell>
                                  <TableCell padding='24px' direction='column' justify='center' grow='1'>
                                    <Paragraph variant='p2'>{preference.name}</Paragraph>
                                    <If condition={!!preference.description} >
                                      <Paragraph variant='p1'>{preference.description}</Paragraph>
                                    </If>
                                  </TableCell>
                                </TableRow>
                                <Rule color='slate'/>
                              </For>
                            </CardContainer>
                            <Spacer universal='16px'/>
                            <ButtonContainer>
                              <Link
                                bold
                                color={theme.colors.oranges.coral}
                                onClick={() => _handleToggleCategory(category)}>{getCategoryButtonText(category)}</Link>
                            </ButtonContainer>
                          </ContentCard>
                          <CardSeperator />
                        </MobileContainer>
                      </When>
                      <Otherwise>
                        <DesktopContainer key={category.id}>
                          <ContentCard title={category.name} headerType={ContentCardHeaderEnum.TITLE_ONLY}>
                            <CardContainer>
                              <For each='preference' of={category.preferences}>
                                <TableRow>
                                  <TableCell align='center' justify='center' padding='0 12px 0 24px'>
                                    <CheckboxContainer>
                                      <CheckBox
                                        name={`${category.id}|${preference.id}`}
                                        showLabel={false}
                                        showOptional={false}
                                        options={[{ title: '', value: Utilities.parseBoolean(preference.userValue) }]}
                                        className='check-input' />
                                    </CheckboxContainer>
                                  </TableCell>
                                  <TableCell direction='column' grow='1' padding='24px'>
                                    <Paragraph variant='p2'>{preference.name}</Paragraph>
                                    <If condition={!!preference.description} >
                                      <Paragraph variant='p1'>{preference.description}</Paragraph>
                                    </If>
                                  </TableCell>
                                </TableRow>
                                <Rule color='slate' />
                              </For>
                            </CardContainer>
                            <Spacer universal='16px'/>
                            <ButtonContainer>
                              <Link
                                bold
                                color={theme.colors.oranges.coral}
                                onClick={() => {
                                  _handleToggleCategory(category)
                                }}>{getCategoryButtonText(category)}</Link>
                            </ButtonContainer>
                          </ContentCard>
                          <CardSeperator />
                        </DesktopContainer>
                      </Otherwise>
                    </Choose>
                  </For>
                </Form>
              </When>
              <Otherwise>
                <SectionLoading />
              </Otherwise>
            </Choose>
          </Container>
        </When>
        <When condition={state.loading || preferencesLoading}>
          <SectionLoading />
        </When>
        <Otherwise>
          <></>
        </Otherwise>
      </Choose>
    </>
  )

}
